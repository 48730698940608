nav{
    position: fixed;
    bottom: 0%;
    left: 0%;
    right: 0%;
    margin: 0% auto;
    z-index: 999;
}
#nav-box{
    background-color: #191c76a6;
    backdrop-filter: blur(4.4px);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1)
                ,inset 5px 0px 40px 0px #fff;
    border: 1px solid rgba(241, 241, 253, 0.99);
    border-radius: 90px;
    bottom: 0%;
    padding: 0% 2%;
    width: fit-content;
    max-width: 100%;
    margin: 0% auto;
}
.navul{
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin: 0%;
    padding:1em;
}

a{
    text-decoration: none;
}


.bi-easel3-fill:hover{
    color: green;
}
.bi-lightbulb-fill:hover, .bi-stars:hover{
    color: yellow;
}
.bi-globe2:hover{
    color: mediumvioletred;
}
.bi-easel3-fill:hover, .bi-person-circle:hover, .bi-lightbulb-fill:hover, .bi-file-earmark-person:hover, .bi-stars:hover, .bi-telephone-fill:hover, .bi-globe2:hover{
    font-size: 3em !important;
}
.bi{
    font-size: 2em;
    transition: 1s;
}


#palflag, #palflagl{
    position: fixed;
    left: -1vh;
    bottom: 10vh;
    width: 10rem;
  }
  
  #palflagl{
    left: initial;
    right: -1vh;
    transform: rotateY(180deg);
  }
  .palgrate{
    width: 100%;
    text-align: center;
    color: red;
    font-family: "blood";
    animation: 1s glow ease infinite;
    text-transform: capitalize;
  } 
  @keyframes glow {
    0% {
      text-shadow: 0 0 10px #ff000040, 0 0 20px #ff000040, 0 0 30px #ff000040, 0 0 40px #ff000040, 0 0 50px #ff000040, 0 0 60px #ff000040, 0 0 70px #ff000040;
    }
    
    50% {
      text-shadow: 0 0 10px #ff000000, 0 0 20px #ff000000, 0 0 30px #ff000000, 0 0 40px #ff000000, 0 0 50px #ff000000, 0 0 60px #ff000000, 0 0 70px #ff000000;
    }
    100%{
      text-shadow: 0 0 10px #ff000040, 0 0 20px #ff000040, 0 0 30px #ff000040, 0 0 40px #ff000040, 0 0 50px #ff000040, 0 0 60px #ff000040, 0 0 70px #ff000040;
    }
  }
  
  @media screen and (max-width: 425px) {
    #palflagl, #palflag{
      width: 6em;
    }
    /* .palgrate{
      bottom: 10em;
    } */
  }