#Blog{
    border: solid black 3px;
    border-radius: 20px;
    position: relative;
    padding: .5em !important;
    background-color: rgba(255, 255, 255, 0.089);
    margin: 0 2%;
    height: 80vh;
    max-height: fit-content;
    align-items:stretch;
}
#BlogImg{
    border-radius: 20px;
    aspect-ratio: 1/1;
    min-width: 100%;
}
.BlogInfo{
    text-align: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
.BlogInfo *{
    font-size: .8em !important;
}
#BlogDetailImg{
    width: 100%;
    max-height: 60vh;
}
.Blogdes{
    background-color: rgba(0, 0, 0, 0.8);
    min-width: 50vw;
    padding: 1%;
    border-radius: 16px;
    margin-right: 5%;
}
.Blogdesl{
    margin-left: 5%;
}